import { faContactCard, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import NavBar from './Component/NavBar/NavBar';

import Pages from './Pages/Pages.rout';


import gmail from "../src/Imgs/SVGComponets/gmail.svg";
import telegram from "../src/Imgs/SVGComponets/telegram.svg";
import facebook from "../src/Imgs/SVGComponets/facebook.svg";
import whatsapp from "../src/Imgs/SVGComponets/whatsapp.svg";
// import fiverr from "../src/Imgs/SVGComponets/insta.svg";
import insta from "../src/Imgs/SVGComponets/insta.svg";
// import upwork from "../src/Imgs/SVGComponets/upwork.svg";

// import contact_us from '../../Imgs/reduce contact us.png'

// Chanell openning

function EmailOpen(){
  window.open("https://mail.google.com/mail/u/2/#inbox")
    }
function TelegramOpen(){
  window.open("https://t.me/rangaubot")
    }

function FacebookOpen(){
  window.open("https://web.facebook.com/ubot.trading.9/")
    }

function FiverrOpen(){
  window.open("https://instagram.com/rangatechnologies?igshid=NGExMmI2YTkyZg==")
    }

// function UpworkOpen(){
//   window.open("https://www.upwork.com")
//     }

function WhatsAppOpen(){
  window.open("https://wa.me/message/XEABYADPSDL2D1")
    }


function App() {
  return (

    <div className='h-screen overflow-x-hidden scroll-smooth overflow-y-visible ' id="page-container">
      <div className='sticky top-0 w-full z-40'>
        <NavBar />
      </div>
      <div className='overflow-hidden h-fit'>
        <Pages />

        <div>

          
    
    {/* Start a conversation */}
    {/* <div className="  mx-auto py-8 bg-[#ffffff]"> */}
    {/* <h1 className=" text-4xl text-center font-sans font-bold ">Use Cases</h1> */}
    {/* <br /> */}

      {/* <h1 className="text-3xl font-bold mb-4">Responsive Box Design</h1> */}
      <div className="grid grid-cols-2 gap-1">
        {/* Box 1 */}
       {/* <div>
       <img className="hover:animate-pulse transform transition-all " src={contact_us} />
       </div> */}


        </div>
        </div>

        
        {/* </div> */}



        <footer className='bg-primary w-full text-colorid4e749ecb justify-center items-center flex flex-col py-12'>
          <div className='flex  flex-col text-center items-center font-bold text-3xl'>
            
            {/* <FontAwesomeIcon icon={faTowerCell} className="h-10" /> */}
            The Bee Online
          </div>

          {/* <div className=' grid grid-cols-2 md:grid-cols-5 items-center w-fit justify-center mt-10 space-x-14 space-y-2'> */}
            {/* <div className=' ml-14 flex flex-row justify-center items-center text-lg space-x-2'>
            <button onClick={() => window.open("ranganaupul@gmail.com")}  className="button button4" >
                      <div >
                      <svg className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 transform w-7 h-7 fill-primary hover:fill-[#ea4335]"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24">
  <path
    d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
    fill-rule="evenodd"
    clip-rule="evenodd" />
</svg>
                        </div>                        
                    </button> 
            </div> */}

            {/* <div className=' flex flex-row justify-center items-center text-lg space-x-2'>
            <button onClick={() => window.open("https://t.me/rangaubot")}  className=" button button2" >
                      <div >
                      <svg className=" hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 transform h-7 w-7 fill-primary hover:fill-[#0088cc]"
  fill="currentColor"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"

  >
  <path
    id="telegram-1"
    d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z" />
</svg>
                        
                        </div>                        
                    </button>
            </div> */}

            {/* <div className=' flex flex-row justify-center items-center text-lg space-x-2'>
            <button onClick={() => window.open("https://web.facebook.com/ubot.trading.9/")}  className="button button3" >
                      <div  >
                      <svg className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 transform w-7 h-7 fill-primary hover:fill-[#1877f2]"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 24 24">
  <path
    d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
</svg>
                        
                        </div>                        
                    </button>
            </div> */}

            {/* <div className=' flex flex-row justify-center items-center text-lg space-x-2'>
            <button onClick={() => window.open("https://instagram.com/rangatechnologies?igshid=NGExMmI2YTkyZg==")}  className="button button4" >
                      <div  >
                      <svg className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 transform w-7 h-7 fill-primary hover:fill-[#c13584]"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 24 24">
  <path
    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
</svg>
                        
                        </div>                        
                    </button>
            </div> */}
            {/* <div className=' flex flex-row justify-center items-center text-lg space-x-2'>
            <button className="button button1" onClick={UpworkOpen}  >
                        <div  className="img" >
                          <img  alt="" src={upwork} />
                        </div>                        
                      </button> 
            </div> */}
            {/* <div className=' flex flex-row justify-center items-center text-lg space-x-2'>
            <button onClick={() => window.open("https://wa.me/message/XEABYADPSDL2D1")}  className="button button6" >
                      <div  >
                      <svg className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 transform h-7 w-7 fill-primary hover:fill-[#128c7e]"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24">
  <path
    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
</svg>

                        </div>                        
                    </button>
            </div> */}
          {/* </div> */}
          <div style={{fontWeight:'bold', padding:'20px', fontSize: '20px'}}>
            
            {/* <h1>Email : branganaupul@gmail.com</h1>
            <h2>Contact Number  : +94 76 9298 578</h2> */}
          </div>

        
        
         

  <div className="container px-6 pt-6">

    <div className="mb-6 flex justify-center">


      <a
        href="https://web.facebook.com/ubot.trading.9/"
        type="button"
        className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 hover:fill-[#128c7e] m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
        data-te-ripple-init
        data-te-ripple-color="light">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-full w-4"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      </a>

      <a
        href="#!"
        type="button"
        className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 hover:fill-[#128c7e] m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
        data-te-ripple-init
        data-te-ripple-color="light">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-full w-4"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      </a>

      <a
        href="ranganaupul@gmail.com"
        type="button"
        className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 hover:fill-[#128c7e] m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
        data-te-ripple-init
        data-te-ripple-color="light">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-full w-4"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
            fill-rule="evenodd"
            clip-rule="evenodd" />
        </svg>
      </a>

      <a
        href="https://t.me/rangaubot"
        type="button"
        className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 hover:fill-[#128c7e] m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
        data-te-ripple-init
        data-te-ripple-color="light">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-full w-4"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
      </a>

      <a
        href="https://linkedin.com/in/ranga-bot-8046b2256"
        type="button"
        className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 hover:fill-[#128c7e] m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
        data-te-ripple-init
        data-te-ripple-color="light">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-full w-4"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
        </svg>
      </a>

      <a
        href="https://wa.me/message/XEABYADPSDL2D1"
        type="button"
        className="hover:animate-bounce hover:h-10 hover:w-10 hover:duration-300 hover:fill-[#128c7e] transform m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
        data-te-ripple-init
        data-te-ripple-color="light">

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-full w-4"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
        </svg>
      </a>
    </div>

    {/* <!-- Newsletter sign-up form --> */}
    <div>
      <form action="">
        <div
          className="gird-cols-1 grid items-center justify-center gap-4 md:grid-cols-3">
          <div className="md:mb-6 md:ml-auto">
            <p className="">
              <strong>Meld je aan voor onze nieuwsbrief</strong>
            </p>
          </div>

          {/* <!-- Newsletter sign-up input field --> */}
          <div className="relative md:mb-6" data-te-input-wrapper-init>
            <input
              type="text"
              className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] text-neutral-200 outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              id="exampleFormControlInput1"
              placeholder="Email address" />
            <label
              // for="exampleFormControlInput1"
              // class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-200 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-neutral-200 peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-neutral-200"
              >E-mailadres
            </label>
          </div>

          {/* <!-- Newsletter sign-up submit button --> */}
          <div className="mb-6 md:mr-auto">
            <button
              type="submit"
              className="inline-block rounded border-2 border-neutral-50 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
              data-te-ripple-color="light">
              Abonneren
            </button>
          </div>
        </div>
      </form>
    </div>

    {/* <!-- Copyright information --> */}


  </div>

  {/* <!-- Copyright section --> */}
  <div
    className="p-4 text-center bg-primary"
    >
      <h2>  © 2023 Copyright: The Bee Online</h2>
  </div>





        </footer>




      </div>
    </div>
  );
}

export default App;
